<template>
  <b-alert v-show="version_update" show variant="warning">有新的系統版本! 5秒後將重新載入</b-alert>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            style="display: none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              <!--
              <b-button
                variant="secondary"
                href="./#/Dashboard"
                @click="home_page()"
              >
                {{ $t('common.button.home') }}
              </b-button>
              -->
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->

  <!-- Modal for Request-->
  <div>
    <b-button
      id="requestModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#requestModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="requestModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="requestModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="requestModalLabel" class="modal-title">技術支援</h5>
          <button
            id="requestModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span style="color:red;">請輸入問題 / 要求。IT部將稍後處理。</span>
              <br>
              <CFormInput
                v-model="request_text"
                size="sm"
                @keyup.enter="action_auth('request')"
              />
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="action_auth('request')">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Request-->

  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-4 text-left"></div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <span>預約表 - Call Team</span>
          </div>
        </div>
      </div>
      <div class="col-4 text-right">
        <span style="font-size: 13px;">Version {{ version }}&nbsp;</span>
      </div>
    </div>

    <div id="fullscreen" class="bg-white">
      <!-- Modal for Event -->
      <div
        id="eventModal"
        class="modal fade"
        data-bs-keyboard="false"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">
              <span v-if="eventAction == 'create'">新預約</span>
              <span v-else>修改預約 (Booking ID:{{ eventInfo.id }})</span>
            </h5>
            <b-button
              variant="danger"
              @click="action_request()"
              v-if="eventAction != 'create'"
            >
              <span>技術支援</span>
            </b-button>
          </div>
          <div class="modal-body">
            <div v-if="eventInfo">
              <div>
                <div class="row">
                  <div class="col-3 text-left">
                    <span>電話號碼 / 會員編號</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>名稱</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>別名</span>
                  </div>
                  <div class="col-1 text-left">
                    <span>年齡</span>
                  </div>
                  <div class="col-2 text-left">
                    <span>性別</span>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <CFormInput
                        id="member_no"
                        ref="member_no"
                        v-model="eventInfo.member_no"
                        size="sm"
                        type="text"
                        @change="member_check(eventInfo)"
                      />
                    </div>
                    <div class="col-3 text-left">
                      <span :style="eventInfo.vaild_member ? '' : 'color:red;'">{{ eventInfo.name }}</span>
                    </div>
                    <div class="col-3 text-left">
                      <CFormInput
                      id="nickname"
                      ref="nickname"
                      v-model="eventInfo.nickname"
                      size="sm"
                      type="text"
                    />
                    </div>
                    <div class="col-1 text-left">
                      <CFormInput
                        v-model="eventInfo.age"
                        size="sm"
                        type="text"
                      />
                    </div>
                    <div class="col-2 text-left">
                      <b-form-radio-group
                        v-model="eventInfo.gender"
                        :options="gender_list"
                        value-field="value"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-radio-group>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3 text-left">
                      <span>分店</span>
                    </div>
                    <div class="col-3 text-left">
                      <span>預約日期</span>
                    </div>
                    <div class="col-3 text-left">
                      <span>開始時間</span>
                    </div>
                    <div class="col-3 text-left">
                      <span>結束時間</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.branch_id"
                        mode="single"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="branch_list"
                        :allow-empty="false"
                        :canClear="false"
                        valueProp="id"
                        trackBy="branch"
                        label="branch"
                      />
                    </div>
                    <div class="col-3 text-left">
                      <datepicker
                        v-model="eventInfo.bookingDate"
                        auto-apply
                        input-format="yyyy-MM-dd"
                        :clearable="false"
                        :enable-time-picker="false"
                        @update:modelValue="change_bookingDate(eventInfo)"
                      />
                    </div>
                    <div class="col-3 text-left">
                      <datepicker
                        v-model="eventInfo.bookingStartTime"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="30"
                        :min-time="{ hours: 11}"
                        :max-time="{ hours: 22, minutes: 30}"
                        auto-apply
                        @update:modelValue="change_bookingStartTime()"
                        textInput
                      />
                    </div>
                    <div class="col-3 text-left">
                      <datepicker
                        v-model="eventInfo.bookingEndTime"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="30"
                        :min-time="{ hours: 11}"
                        :max-time="{ hours: 22, minutes: 30}"
                        auto-apply
                        @update:modelValue="change_bookingEndTime()"
                        textInput
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <span>客戶種類</span>
                    </div>
                    <div class="col-9 text-left">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.member_type"
                        mode="single"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="member_type_list"
                        :allow-empty="false"
                        :canClear="false"
                        valueProp="id"
                        trackBy="name_tc"
                        label="name_tc"
                      />
                    </div>
                    <div class="col-9 text-left">
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-12 text-left">
                      <span>備註</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <CFormInput
                        id="remark_call"
                        ref="remark_call"
                        v-model="eventInfo.remark_call"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.cancel_reason">
                    <div class="col-12 text-left">
                        <span style="color:red;">取消原因: {{ getlistitem('cancel_reason', eventInfo.cancel_reason) }}</span><span v-if="eventInfo.cancel_reason == 8"> ({{eventInfo.cancel_reason_remark}})</span>
                    </div>
                  </div>
                  <div class="row" v-show="therapist_booked">
                    <div class="col-12 text-left">
                      <span style="color:red;">{{ therapist_booked }}在該時段已有booking</span>
                      <b-form-checkbox
                        v-model="update_sync_delete"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>更新時將{{ therapist_booked }}在該時段的原有Booking轉為</span><span style="color:red;">舊客Waiting List</span>
                      </b-form-checkbox>
                      <br>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <!-- Cancel button -->
                  <b-button
                    variant="danger"
                    @click="cancel_booking()"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && !eventInfo.arrival_time"
                  >
                    <span>取消預約</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && !eventInfo.arrival_time">&nbsp;</span>
                  <!-- End cancel button -->

                  <!-- Back button -->
                  <b-button
                    variant="secondary"
                    @click="back()"
                  >
                    {{ $t('common.button.back') }}
                  </b-button>
                  <span>&nbsp;</span>
                  <!-- End back button -->

                  <!-- Submit button -->
                  <b-button v-if="(eventInfo.member_no || eventInfo.special_event > 0) && eventInfo.org_therapist.length > 0 && (eventInfo.bookingStartTime.hours != eventInfo.bookingEndTime.hours || eventInfo.bookingStartTime.minutes != eventInfo.bookingEndTime.minutes) && !eventInfo.checkout_date && eventInfo.member_type && eventInfo.branch_id" variant="success" @click="action_auth('submit')">
                    <span v-if="eventInfo.id == 0">{{ $t('common.button.submit') }}</span>
                    <span v-if="eventInfo.id > 0">{{ $t('common.button.update') }}</span>
                    <span v-if="eventInfo.status != 1">{{ $t('common.button.booking') }}</span>
                  </b-button>
                  <span v-if="(eventInfo.member_no || eventInfo.special_event > 0) && !eventInfo.checkout_date">&nbsp;</span>
                  <!-- End submit button -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="display: none">
            <button
              id="eventModal_close"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <b-button
      id="eventModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#eventModal"
      >{{ $t('common.button.confirm') }}
    </b-button>
    <!-- End of Modal for Event -->

    <!-- Whatsapp History -->
    <div class="offcanvas offcanvas-end offcanvas-width" tabindex="-1" id="conversation">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">Whatsapp對話記錄</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="conversation.conversation && conversation.conversation.length > 0">
          <template v-for="item in conversation.conversation" :key="item.id">
            <template v-if="item.statusString && item.statusString == 'READ'">
              <div class="row">
                <div class="col-12 text-right">
                  <b>ANKH機能再生</b><span> ({{ date_formatter(item.created) }}):</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <span v-if="item.finalText">{{ item.finalText }}</span>
                  <span v-if="item.text">{{ item.text }}</span>
                </div>
              </div>
              <hr>
            </template>
            <template v-if="item.statusString && item.statusString == 'SENT'">
              <div class="row">
                <div class="col-12 text-left">
                  <b>{{ conversation.phone }}</b><span> ({{ date_formatter(item.created) }}):</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-left">
                  <span v-if="item.type == 'text' || item.type == 'image'">{{ item.text }}</span>
                  <span v-if="item.type == 'audio'">[語音]</span>
                </div>
              </div>
              <hr>
            </template>
          </template>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有對話記錄</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Whatsapp History -->

    <!-- Confirm delete modal-->
    <div>
      <b-modal
        v-model="modalShow"
        class="text-secondary mb-0"
        hide-footer
        :title="$t('common.message.warning')"
      >
        <div>
          <div class="card-header pb-0">
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modalShow">
                <h5 class="text-delete-warning">
                  {{ $t('common.button.confirm') }}取消預約？
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-left">
                <Multiselect
                  v-model="eventInfo.cancel_reason"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="false"
                  :options="cancel_reason_list"
                  :allow-empty="false"
                  :canClear="true"
                  valueProp="id"
                  trackBy="name_tc"
                  label="name_tc"
                  placeholder="請選擇取消原因"
                />
              </div>
            </div>
            <div v-if="eventInfo.cancel_reason == 8" class="row">
              <div class="col-12 text-left">
                <CFormInput
                  v-model="eventInfo.cancel_reason_remark"
                  type="text"
                  placeholder="其他原因"
                />
              </div>
            </div>
            <br>
            <div class="d-flex flex-row justify-content-between">
              <div></div>
              <div>
                <b-button variant="secondary" @click="cancel_delete()">{{
                  $t('common.button.cancel')
                }}</b-button>
                &nbsp;
                <b-button
                  variant="danger"
                  @click="action_auth('confirm_delete')"
                  >{{ $t('common.button.confirm') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- End of delete modal-->

    <div class="row">
      <div class="col-4 text-right"></div>
      <div class="col-4 text-center">
        <span style="color:green;">預約列表</span>
      </div>
      <div class="col-2 text-right">
        <b-button
          id="conversationbutton"
          class="mx-1 position-relative"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#conversation"
          style="display: none"
        >
          <span>Roster</span>
        </b-button>
      </div>
      <div class="col-2 text-right">
        <b-button
          class="mx-1 position-relative"
          size="sm"
          variant="outline-warning"
          @click="get_api()"
          >
            <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
            <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
              <span style="color: red; font-size: 1rem !important;">!</span>
            </b-badge>
        </b-button>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-2 text-left">
        <datepicker
          v-model="currentDateFormat"
          size="sm"
          auto-apply
          input-format="yyyy-MM-dd"
          :clearable="false"
          :enable-time-picker="false"
          @update:modelValue="change_day()"
        />
      </div>
      <div class="col-1 text-left">
        <span>(星期{{date_day}})</span>
      </div>
      <div class="col-1 text-left">
      </div>
      <div class="col-2 text-right">
        <Multiselect
          v-if="!role_check(23)"
          v-model="filtersourceList.created_by"
          mode="single"
          :close-on-select="true"
          :searchable="true"
          :create-option="false"
          :options="userList"
          :allow-empty="false"
          :canClear="true"
          valueProp="id"
          trackBy="nickname"
          label="nickname"
          placeholder="建立者"
        />
      </div>
      <div class="col-2 text-right">
        <Multiselect
          v-model="filtersourceList.branch_id"
          mode="single"
          :close-on-select="true"
          :searchable="true"
          :create-option="false"
          :options="branch_list"
          :allow-empty="false"
          :canClear="true"
          valueProp="id"
          trackBy="branch"
          label="branch"
          placeholder="選擇分店"
        />
      </div>
      <div class="col-2 text-right">
        <CFormInput
          v-model="filtersourceList.freetext"
          type="text"
          placeholder="輸入電話號碼 / 會員編號 / 備註搜尋當日預約記錄"
        />
      </div>
      <div class="col-1 text-right">
        <Multiselect
          v-model="filtersourceList.status"
          mode="single"
          :close-on-select="true"
          :searchable="false"
          :create-option="false"
          :options="status_list"
          :allow-empty="false"
          :canClear="false"
          valueProp="id"
          trackBy="name"
          label="name"
        />
      </div>
      <div class="col-1 text-right">
        <b-button
          class="mx-1 position-relative"
          pill
          variant="success"
          @click="showPopup()"
          style="font-size: 1.1rem;"
          v-if="!role_check(24)"
        >
          <span>新增</span>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table-lite
          :is-slot-mode="true"
          :columns="getField"
          :rows="dataSourceList"
          :is-hide-paging="true"
        >
          <template #member="data">
            <div v-if="data.value.member_no" :class="data.value.created_from != '1' ? data.value.gender == 'M' ? 'genderM genderMBG' : 'genderF genderFBG' : 'genderN'">
              <div v-if="data.value.created_from != '1'">
                <span v-show="data.value.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                <span v-show="data.value.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                <template v-if="data.value.vaild_member">
                  <b>{{ data.value.member_no.toUpperCase() }}&nbsp;</b>
                </template>
                <template v-else>
                  <b style="color:red;">沒有會員編號&nbsp;</b>
                </template>
                <br>
                <b v-show="data.value.nickname && !role_check(24)">({{ data.value.nickname }})&nbsp;</b>
                <b v-show="!data.value.nickname && !role_check(24)">({{ data.value.name }})&nbsp;</b>
                <b v-show="data.value.member_type">({{ getlistitem('member_type', data.value.member_type) }})&nbsp;</b>
                <b v-show="data.value.cancel_reason" style="color:red;"><br>取消原因: {{ getlistitem('cancel_reason', data.value.cancel_reason) }}</b><b v-if="data.value.cancel_reason == 8"> ({{data.value.cancel_reason_remark}})</b>
              </div>
              <div v-else>
                <span>沒有檢視權限</span>
              </div>
            </div>
          </template>
          <template #age="data">
            <div v-if="(staff_id == data.value.created_by || role_check(19) || role_check(26)) && data.value.created_from != '1'">
              <span v-if="data.value.age">{{ data.value.age }}</span>
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>
          <template #arrival_time="data">
            <div v-if="data.value.arrival_time && data.value.created_from != '1'">
              <span>{{ data.value.arrival_time }}</span>
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>
          <template #phone="data">
            <div v-if="data.value.created_from != '1' && data.value.phone && !role_check(24)">
              <span v-b-tooltip.hover :title="data.value.phone" v-if="(staff_id == data.value.created_by || role_check(19) || role_check(26))">{{ data.value.phone.slice(0,4) }}****</span>
              <span v-else>{{ data.value.phone.slice(0,4) }}****</span>
              <br>
              <span class="need_update" v-if="data.value.first_conversation && (role_check(19) || role_check(26))">{{ data.value.first_conversation.slice(0,10) }}.....&nbsp;</span>
              <b-avatar v-if="data.value.first_conversation && (role_check(19) || role_check(26))" button @click="whatsapp_conversation(data.value, 'click')" variant="light" v-b-tooltip.hover title="Whatsapp對話">
                <span><font-awesome-icon :icon="['fas', 'comment']" size="lg"/></span>
              </b-avatar>
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>
          <template #booking_show="data">
            <div class="row">
              <div class="col-4 text-center"></div>
              <div class="col-4 text-center">
                <b-form-checkbox
                  v-model="data.value.booking_show"
                  value="1"
                  unchecked-value="0"
                  @change="inline_edit(data.value)"
                  v-if="(staff_id == data.value.created_by || role_check(19) || role_check(26)) && data.value.created_from != '1'"
                >
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="data.value.booking_show"
                  value="1"
                  unchecked-value="0"
                  :disabled="true"
                  v-else
                >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-center"></div>
            </div>
          </template>
          <template #deal="data">
            <div class="row">
              <div class="col-4 text-center"></div>
              <div class="col-4 text-center">
                <b-form-checkbox
                  v-model="data.value.deal"
                  value="1"
                  unchecked-value="0"
                  @change="inline_edit(data.value)"
                  v-if="(staff_id == data.value.created_by || role_check(19) || role_check(26)) && data.value.created_from != '1'"
                >
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="data.value.deal"
                  value="1"
                  unchecked-value="0"
                  :disabled="true"
                  v-else
                >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-center"></div>
            </div>
          </template>
          <template #booking_time="data">
            {{ data.value.start_time }}-{{ data.value.end_time }}
          </template>
          <template #remark_call="data">
            <div v-if="data.value.created_from != '1'">
              {{ data.value.remark_call }}
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>
          <template #branch="data">
            <span>{{ getlistitem('branch', data.value.branch_id) }}</span>
          </template>
          <template #created_from="data">
            <span v-if="data.value.created_from == '1'">分店</span>
            <span v-if="data.value.created_from != '1'">{{ getlistitem('staff', data.value.created_by) }}</span>
          </template>
          <template #action="data">
            <div v-if="(staff_id == data.value.created_by || role_check(19) || role_check(26)) && data.value.created_from != '1'">
              <b-avatar button @click="booking_click(data.value)" variant="light" v-b-tooltip.hover title="更新">
                <span><font-awesome-icon :icon="['fas', 'pen']" size="lg"/></span>
              </b-avatar>
            </div>
          </template>
        </table-lite>
      </div>
    </div>
    <div class="row">
      <div class="col-1 text-center" v-for="item in branch_list" :key="item.id">
        <span>{{ item.branch }}</span><br>
        <span>{{ count_booking(item.id) }}</span>
      </div>
      <div class="col-1 text-center">
        <span>Total</span><br>
        <span>{{ count_booking('all') }}</span>
      </div>
    </div>
    </div>
  </b-overlay>
</template>

<script>
export default {
  components: {
  },
  name: 'BookingList',
  data() {
    return {
      allowed: false,
      modalShow: false,
      need_update: false,
      version: '',
      version_update: false,
      branch_id: '',
      staff: '',
      staff_code: '',
      staff_id: '',
      staff_list: [],
      action_by: '',
      action_type: '',
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      role: [],
      permission: [],
      member_no: '',
      currentDate: new Date(),
      currentDateFormat: '',
      endDateFormat: '',
      show: false,
      sourceList: 'active',
      userList: [],
      filtersourceList: {
        freetext: '',
        branch_id: '',
        created_by: '',
        status: '1',
      },
      cancel_reason_list: [],
      lang: '',
      eventAction: '',
      eventInfo: [],
      old_eventInfo: [],
      branch_list: [],
      gender_list: [
        { value: 'F', name: "F" },
        { value: 'M', name: 'M' },
      ],
      status_list: [
        { id: '1', name: "有效" },
        { id: '-1', name: '已取消' },
      ],
      therapist_booked: '',
      bookingDatedataSource: [],
      roster_list: [],
      member_type_list: [],
      therapist_list: [
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ],
      dataSource: [],
      currentTime: '',
      today_date: '',
      update_sync_delete: '0',
      booking_idList: [],
      orderBy: 'start_time',
      orderByFM: 'ASC',
      conversation: [],
    }
  },
  computed: {
    dataSourceList: function () {
      return this.get_dataSourceList()
    },
    getField: function () {
      const fields = [
        {
          label: this.$t('menu.form.member'),
          field: 'member',
          sortable: false,
          isKey: false,
          width: "20%",
        },
        {
          label: '年齡',
          field: 'age',
          sortable: false,
          isKey: false,
          width: "6%",
        },
        {
          label: '到達時間',
          field: 'arrival_time',
          sortable: false,
          isKey: false,
          width: "6%",
        },
        {
          label: '電話號碼',
          field: 'phone',
          sortable: false,
          isKey: false,
          width: "15%",
        },
        {
          label: 'Show',
          field: 'booking_show',
          sortable: false,
          isKey: false,
          width: "4%",
        },
        {
          label: 'Deal',
          field: 'deal',
          sortable: false,
          isKey: false,
          width: "4%",
        },
        {
          label: '備註',
          field: 'remark_call',
          sortable: false,
          isKey: false,
          width: "25%",
        },
        {
          label: '時間',
          field: 'booking_time',
          sortable: false,
          isKey: false,
          width: "8%",
        },
        {
          label: '分店',
          field: 'branch',
          sortable: false,
          isKey: false,
          width: "6%",
        },
        {
          label: '建立者',
          field: 'created_from',
          sortable: false,
          isKey: false,
          width: "9%",
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          isKey: false,
          width: "10%",
        }
      ]
      return fields
    },
  },
  watch: {
  },
  created() {
    const self = this
    var fd_offset = self.currentDate.getTimezoneOffset()
    var date = new Date(self.currentDate.getTime() - fd_offset * 60 * 1000)
    var date_day = new Date().getDay()
    var day_list = ['日', '一', '二', '三', '四', '五', '六']
    self.date_day = day_list[date_day]
    self.currentDateFormat = date.toISOString().split('T')[0]
    self.today_date = date.toISOString().split('T')[0]
    self.endDateFormat = self.currentDateFormat
    self.lang = localStorage.getItem('locale')
    setTimeout(function () {
      document.getElementById('confirmModal_open').click()
      setTimeout(function () {
        document.getElementById('staff_code').focus()
      }, 500)
    }, 300)
    setInterval(this.getNow, 1000)
    self.getrealip()
  },
  mounted() {
    const self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
    window.Echo.channel('booking_channel')
    .listen('.booking', (e) => {
      if(e.message){
        self.need_update = true
      }
    })
  },
  methods: {
    getrealip(){
      const self = this
      this.axios
        .get('https://api.ipify.org?format=json', {})
        .then(function (res) {
          const ip = res.data.ip
          if(ip == '103.252.76.54' || ip == '103.247.166.198' || ip == '223.255.185.46' || ip == '202.130.127.38' || ip == '183.178.147.150'){
            self.allowed = true
          }
        })
        .catch((error) => { 
          console.error(error)
          self.allowed = true
        })
    },
    getNow: function() {
      const self = this
      const today = new Date();
      const time = today.getHours().toString().padStart(2, '0') + ":" + today.getMinutes().toString().padStart(2, '0')
      self.currentTime = time
    },
    therapist_check(){
      const self = this
      var dataSource = []
      var therapist_nameList = []
      self.booking_idList  = []
      self.update_sync_delete = '0'
      self.therapist_booked = ''
      setTimeout(function () {
        if(self.eventInfo.org_therapist.length > 0 && self.eventInfo.start_time){
          self.eventInfo.org_therapist.forEach((therapist) => {
            if(therapist != "9999" && therapist != "99999"){
              dataSource = self.bookingDatedataSource.filter(function (item) {
                return item.id != self.eventInfo.id && item.therapist == therapist && item.status == 1
              })
              dataSource.forEach((booking) => {
                if((self.eventInfo.start_time >= booking.start_time && self.eventInfo.end_time <= booking.end_time) || (self.eventInfo.end_time > booking.end_time && self.eventInfo.start_time < booking.end_time) || (booking.start_time > self.eventInfo.start_time && self.eventInfo.end_time > booking.start_time)){
                  self.booking_idList.push(booking.id)
                  therapist_nameList.push(therapist)
                }
              })
            }
          })
          if(therapist_nameList.length > 0){
            self.therapist_booked = self.getlistitem('therapist', therapist_nameList)
          }
        }
      }, 500)
    },
    get_dataSourceList() {
      const self = this
      let list = []
      list = this.dataSource.filter(function (item) {
        return item.therapist == 99999 || item.created_from != 1
      })
      var WTS_role = self.role.findIndex(el => el == '25')
      if(WTS_role >= 0){
        list = this.dataSource.filter(function (item) {
          return item.created_from == 3
        })
      }else{
        list = this.dataSource.filter(function (item) {
          return item.created_from == 2
        })
      }
      if(this.filtersourceList.branch_id){
        list = list.filter(function (item) {
          return item.branch_id == self.filtersourceList.branch_id
        })
      }
      if(this.filtersourceList.freetext){
        list = list.filter(function (item) {
          return item?.remark_call?.includes(self.filtersourceList.freetext) || item?.member_no?.includes(self.filtersourceList.freetext) || item?.phone?.includes(self.filtersourceList.freetext)
        })
      }
      if(this.filtersourceList.created_by){
        list = list.filter(function (item) {
          return item.created_by == self.filtersourceList.created_by
        })
      }
      if(this.filtersourceList.status){
        list = list.filter(function (item) {
          return item.status == self.filtersourceList.status
        })
      }
      return list
    },
    count_booking(id) {
      let list = []
      list = this.get_dataSourceList()
      /*
      list = list.filter(function (item) {
        return item.status == 1 && item.created_from != 1
      })
      */
      if(id != 'all'){
        list = list.filter(function (item) {
          return item.branch_id == id
        })
      }
      return list.length
    },
    booking_click(item) {
      const self = this
      self.therapist_booked = ''
      self.eventAction = "edit"
      self.eventInfo = item
      self.old_eventInfo = {
        id: self.eventInfo.id,
        branch_id: self.eventInfo.branch_id,
        member_no: self.eventInfo.member_no,
        phone: self.eventInfo.phone,
        age: self.eventInfo.age,
        name: self.eventInfo.name,
        nickname: self.eventInfo.nickname,
        gender: self.eventInfo.gender,
        therapist: self.eventInfo.therapist,
        org_therapist: self.eventInfo.org_therapist,
        without_therapist: self.eventInfo.without_therapist,
        advisor: self.eventInfo.advisor,
        nutritionist: self.eventInfo.nutritionist,
        chinese_medicine: self.eventInfo.chinese_medicine,
        bookingDate: self.eventInfo.bookingDate,
        bookingStartTime: self.eventInfo.bookingStartTime,
        bookingEndTime: self.eventInfo.bookingEndTime,
        arrivalTime: self.eventInfo.arrivalTime,
        arrival_time: self.eventInfo.arrival_time,
        startDate: self.eventInfo.startDate,
        endDate: self.eventInfo.endDate,
        start_time: self.eventInfo.start_time,
        end_time: self.eventInfo.end_time,
        room: self.eventInfo.room,
        new_join: self.eventInfo.new_join,
        specify: self.eventInfo.specify,
        recommend: self.eventInfo.recommend,
        reconfirm: self.eventInfo.reconfirm,
        remark: self.eventInfo.remark,
        remark_call: self.eventInfo.remark_call,
        remark_internal: self.eventInfo.remark_internal,
        cancel_reason: self.eventInfo.cancel_reason,
        cancel_reason_remark: self.eventInfo.cancel_reason_remark,
        member_type: self.eventInfo.member_type,
        booking_show: self.eventInfo.booking_show,
        deal: self.eventInfo.deal,
        special_event: self.eventInfo.special_event,
        checkin_date: self.eventInfo.checkin_date,
        checkin_time: self.eventInfo.checkin_time,
        checkout_date: self.eventInfo.checkout_date,
        checkout_time: self.eventInfo.checkout_time,
        status: self.eventInfo.status,
        created_from: self.eventInfo.created_from,
        created_by: self.eventInfo.created_by,
        vaild_member: self.eventInfo.vaild_member,
        conversation: self.eventInfo.conversation,
        first_conversation: self.eventInfo.first_conversation,
      }
      setTimeout(function () {
        document.getElementById('eventModal_open').click()
      }, 300)
      if(self.eventInfo.member_no){
        self.member_check(self.eventInfo)
      }
    },
    inline_edit(item){
      const self = this
      self.eventInfo = item
      self.action_auth('submit')
    },
    showPopup() {
      const self = this
      self.therapist_booked = ''
      self.therapist_list = self.roster_list
      var created_from = 2
      var WTS_role = self.role.findIndex(el => el == '25')
      if(WTS_role >= 0){
        created_from = 3
      }
      self.eventInfo = {
        id: 0,
        member_no: '',
        phone: '',
        age: '',
        name: '',
        gender: '',
        nickname: '',
        therapist: 0,
        org_therapist: ['99999'],
        without_therapist: 0,
        advisor: [],
        nutritionist: [],
        chinese_medicine: [],
        bookingDate: self.currentDateFormat,
        bookingStartTime: {
          hours: '11',
          minutes: '00',
          seconds: '0'
        },
        bookingEndTime: {
          hours: '11',
          minutes: '30',
          seconds: '0'
        },
        arrivalTime: {
          hours: '00',
          minutes: '00',
          seconds: '0'
        },
        arrival_time: '',
        startDate: '',
        endDate: '',
        start_time: '11:00',
        end_time: '11:30',
        room: '',
        new_join: '1',
        specify: '0',
        recommend: '0',
        reconfirm: 'None',
        remark: '',
        remark_call: '',
        remark_internal: '',
        cancel_reason: '',
        member_type: '',
        booking_show: 0,
        deal: 0,
        cancel_reason_remark: '',
        special_event: 0,
        checkin_date: '',
        checkin_time: '',
        checkout_date: '',
        checkout_time: '',
        created_from: created_from,
        status: 1,
        vaild_member: false,
        conversation: [],
        first_conversation: '',
      }
      self.eventAction = "create"
      document.getElementById('eventModal_open').click()
    },
    back() {
      const self = this
      var index = self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)
      if(index >= 0){
        self.old_eventInfo.bookingStartTime = {
          hours: self.old_eventInfo.start_time.substring(0, 2),
          minutes: self.old_eventInfo.start_time.substring(3, 5),
          seconds: '00'
        }
        self.old_eventInfo.bookingEndTime = {
          hours: self.old_eventInfo.end_time.substring(0, 2),
          minutes: self.old_eventInfo.end_time.substring(3, 5),
          seconds: '00'
        }
        self.dataSource[self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)] = self.old_eventInfo
      }
      if(self.dataSource.length > 0){
        if(self.dataSource[self.dataSource.length-1].id == 0){
          self.dataSource.pop()
        }
      }
      document.getElementById('eventModal_close').click()
    },
    submit() {
      const self = this
      if (self.eventInfo.bookingDate.length != 10) {
        var fd_offset = self.eventInfo.bookingDate.getTimezoneOffset()
        var date = new Date(self.eventInfo.bookingDate.getTime() - fd_offset * 60 * 1000)
        self.eventInfo.bookingDate = date.toISOString().split('T')[0]
      }
      self.eventInfo.startDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.endDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.arrival_time = self.eventInfo.arrivalTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.arrivalTime.minutes.toString().padStart(2, '0')
      self.eventInfo.status = 1
      self.update_api()
      document.getElementById('eventModal_close').click()
    },
    cancel_booking() {
      const self = this
      self.back()
      self.modalShow = true
    }, 
    confirm_delete() {
      const self = this
      self.eventInfo.status = -1
      self.update_api()
      self.modalShow = false
      document.getElementById('eventModal_close').click()
    },
    cancel_delete() {
      const self = this
      self.modalShow = false
    },
    initialize() {
      const self = this
      this.formData = new FormData()
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.branch_list = response.data.branch_list.filter(function (item) {
          return item.id != 6
        })
        self.staff_list = response.data.staff_list
        self.cancel_reason_list = response.data.cancel_reason_list
        self.member_type_list = response.data.member_type_list
        setTimeout(function () {
          self.get_api()
        }, 500)
      })
    },
    get_api() {
      const self = this
      self.show = true
      self.dataSource = []
      self.userList = []
      self.roster_list = [
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', self.currentDateFormat)
      this.formData.append('end_booking_date', self.endDateFormat)
      this.formData.append('history', '1')
      this.formData.append('orderBy', self.orderBy)
      this.formData.append('orderByFM', self.orderByFM)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.need_update = false
        if(self.version){
          if(self.version != response.data.version){
            self.version_update = true
            setTimeout(function () {
              self.reload()
            }, 5000)
          }
        }else{
          self.version_update = false
          self.version = response.data.version
        }
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            if(self.staff == 'all' || item.id == self.staff_id){
              item.text = item.nickname
              item.name = item.nickname
              item.color = '#ededed'
              self.roster_list.push(item)
            }
          })
          self.therapist_list = self.roster_list
        }
        self.dataSource = response.data.data
        self.bookingDatedataSource = response.data.data
        if(self.dataSource.length > 0 ){
          self.dataSource.forEach((item, idx, array) => {
            item.vaild_member = true
            if(item.status == 1 && item.name == '找不到相關會員編號' && (item.therapist == 99999 || item.created_from != 1)){
              self.member_check(item)
            }
            item.bookingDate = item.booking_date
            item.startDate = item.bookingDate+'T'+item.start_time+':00'
            item.endDate = item.bookingDate+'T'+item.end_time+':00'
            item.bookingStartTime = {
              hours: item.start_time.substring(0, 2),
              minutes: item.start_time.substring(3, 5),
              seconds: '00'
            }
            item.bookingEndTime = {
              hours: item.end_time.substring(0, 2),
              minutes: item.end_time.substring(3, 5),
              seconds: '00'
            }
            if(item.arrival_time){
              item.arrivalTime = {
                hours: item.arrival_time.substring(0, 2),
                minutes: item.arrival_time.substring(3, 5),
                seconds: '00'
              }
            }else{
              item.arrivalTime = {
                hours: '00',
                minutes: '00',
                seconds: '0'
              }
            }

            // Advisor array
            var advisor = []
            if(item.advisor){
              var advisor_re = item.advisor
              advisor_re = advisor_re.replace('[', '')
              advisor_re = advisor_re.replace(']', '')
              advisor_re = advisor_re.replace('"', '')
              advisor = advisor_re.split(',')
            }
            item.advisor = advisor

            // Chinese Medicine array
            var chinese_medicine = []
            if(item.chinese_medicine){
              var chinese_medicine_re = item.chinese_medicine
              chinese_medicine_re = chinese_medicine_re.replace('[', '')
              chinese_medicine_re = chinese_medicine_re.replace(']', '')
              chinese_medicine_re = chinese_medicine_re.replace('"', '')
              chinese_medicine = chinese_medicine_re.split(',')
            }
            item.chinese_medicine = chinese_medicine

            // Nutritionist array
            var nutritionist = []
            if(item.nutritionist){
              var nutritionist_re = item.nutritionist
              nutritionist_re = nutritionist_re.replace('[', '')
              nutritionist_re = nutritionist_re.replace(']', '')
              nutritionist_re = nutritionist_re.replace('"', '')
              nutritionist = nutritionist_re.split(',')
            }
            item.nutritionist = nutritionist

            item.without_therapist = item.without_therapist.toString()

            if(item.status == 1 && item.created_from != 1){
              var check_user = self.userList.findIndex(el => el.id == item.created_by)
              if(check_user == '-1'){
                var user = {
                  id: item.created_by,
                  nickname: self.getlistitem('staff', item.created_by),
                }
                self.userList.push(user)
              }
            }

            if (idx === array.length - 1){
              setTimeout(function () {
                self.show = false
              }, 300)
            }
            item.conversation = []
            item.first_conversation = ''
            if(item.status == 1 && item.created_from != 1 && item.webhooks == 1){
              setTimeout(function () {
                self.whatsapp_conversation(item, 'auto')
              }, 1000)
            }
          })
        }else{
          setTimeout(function () {
            self.show = false
          }, 300)
        }
      })
    },
    update_api() {
      const self = this
      if(self.update_sync_delete == '1'){
        self.eventInfo.booking_idList = self.booking_idList
      }
      self.show = true
      this.axios
        .post('/booking/update', {
          data: self.eventInfo,
          staff_id: self.staff_id,
          action_by: self.action_by,
        })
        .then(function () {
          self.eventAction = "edit"
          self.get_api()
        })
    },
    action_request(){
      document.getElementById('requestModal_open').click()
    },
    update_request(){
      const self = this
      self.show = true
      this.axios
      .post('/booking/request', {
        data: self.eventInfo,
        action_by: self.action_by,
        request_text: self.request_text,
      })
      .then(function () {
        self.show = false
      })
      .catch((error) => { 
        console.error(error)
        self.show = false
      })
    },
    whatsapp_conversation(info, action) {
      const self = this
      var first_conversation = false
      if (info.phone) {
        //POS API
        this.axios({
        method: 'get',
        url: 'https://serv.ankh.com.hk/api/notify/wati/getmessages/852'+info.phone,
        headers: {
          'Authorization': `Bearer wrM8A9s46NGHPz6Rh9kS5PzPDVM74VjIEXxnQErF`,
          'X-Requested-With': `XMLHttpRequest`,
          'Content-Type': `application/json`,
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.messages){
            if(response.data.messages.items.length > 0){
              info.conversation = response.data.messages.items
              info.conversation.forEach((msg) => {
                if(msg.eventType == 'message' && msg.statusString == 'SENT' && first_conversation == false){
                  info.first_conversation = msg.text
                  first_conversation = true
                }
              })
            }
          }
        })
      }
      if(action == 'click'){
        self.conversation = info
        document.getElementById('conversationbutton').click()
      }
    },
    member_check(info) {
      if (info.member_no) {
        //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: info.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            info.member_no = response.data.member.memberId
            info.name = response.data.member.cardName
            info.phone = response.data.member.phone[0]
            if(!info.age){
              info.age = response.data.member.age
            }
            if(!info.gender){
              info.gender = response.data.member.gender
            }
            info.vaild_member = true
          }else{
            info.age = ''
            info.vaild_member = false
            info.phone = info.member_no
            info.name = "找不到相關會員編號"
          }
        })
      }
    },
    getlistitem(list, id) {
      const self = this
      var selected = []
      var nickname = ""
      if(list == "therapist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.toString().split(",")
          }
          id.forEach((therapist, index) => {
            selected = self.therapist_list.find(i => i.id == therapist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "branch"){
        selected = self.branch_list.find(i => i.id == id)
        if(selected){
          return selected.branch
        }
      }else if(list == "staff"){
        selected = self.staff_list.find(i => i.id == id)
        if(selected){
          return selected.nickname
        }
      }else if(list == "member_type"){
        selected = self.member_type_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }else if(list == "cancel_reason"){
        selected = self.cancel_reason_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }
    },
    change_day() {
      const self = this
      var fd_offset = this.currentDateFormat.getTimezoneOffset()
      var date = new Date(this.currentDateFormat.getTime() - fd_offset * 60 * 1000)
      var date_day = date.getDay()
      var day_list = ['日', '一', '二', '三', '四', '五', '六']
      self.date_day = day_list[date_day]
      self.currentDateFormat = date.toISOString().split('T')[0]
      self.endDateFormat = self.currentDateFormat
      self.get_api()
      if(self.eventAction == "create"){
        self.eventInfo.bookingDate = self.currentDateFormat
      }
    },
    date_formatter(date) {
      var date_format = new Date(date)
      var hour = date_format.getHours().toString().padStart(2, '0')
      var min = date_format.getMinutes().toString().padStart(2, '0')
      var sec = date_format.getSeconds().toString().padStart(2, '0')
      return date.substring(0, 10)+' '+hour+':'+min+':'+sec
    },
    action_auth(type) {
      const self = this
      //document.getElementById('confirmModal_close').style = 'inline'
      self.action_type = type
      self.staff_code = ''
      setTimeout(function () {
        document.getElementById('confirmModal_open').click()
        setTimeout(function () {
          document.getElementById('staff_code').focus()
        }, 500)
      }, 300)
    },
    role_check(role) {
      const self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    staff_code_check() {
      const self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.role = response.data.role
                var view_role = self.role.findIndex(el => el == '18')
                var ip_role = self.role.findIndex(el => el == '20')
                var self_role = self.role.findIndex(el => el == '23')
                if(self_role >= 0){
                  self.filtersourceList.created_by = self.staff_id
                }
                if(view_role >= 0){
                  if(self.allowed == true || ip_role >= 0){
                    self.vaild_staff_code = true
                    self.permission = response.data.permission
                    var index = self.role.findIndex(el => el == '14')
                    if(index >= 0){
                      self.staff = 'all'
                    }else{
                      self.staff = self.staff_id
                    }
                    document.getElementById('confirmModal_close').click()
                    self.staff_code_checked = true
                    self.show = true
                    self.initialize()
                  }else{
                    self.vaild_staff_code = false
                  }
                }else{
                  self.vaild_staff_code = false
                }
              }else {
                self.vaild_staff_code = false
              }
            }else{
              if (staff_info.length > 0) {
                self.action_by = staff_info[0]['id']
                document.getElementById('confirmModal_close').click()
                if(self.action_type == 'submit'){
                  self.submit()
                }else if(self.action_type == 'confirm_delete'){
                  self.confirm_delete()
                }else if(self.action_type == 'request'){
                  self.update_request()
                }
                self.vaild_staff_code = true
              }else {
                self.vaild_staff_code = false
              }
            }
          })
      }
      document.getElementById('staff_code').blur()
    },
    change_bookingStartTime(){
      const self = this
      if(self.eventInfo.bookingStartTime.minutes == "30"){
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.bookingEndTime.minutes = "00"
      }else{
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours
        self.eventInfo.bookingEndTime.minutes = "30"
      }
      self.eventInfo.start_time = self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')
      self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      self.therapist_check()
    },
    change_bookingEndTime(){
      const self = this
      if((self.eventInfo.bookingStartTime.hours > self.eventInfo.bookingEndTime.hours) || (self.eventInfo.bookingStartTime.hours == self.eventInfo.bookingEndTime.hours && self.eventInfo.bookingStartTime.minutes > self.eventInfo.bookingEndTime.minutes)){
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      }else{
        //self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      }
      self.therapist_check()
    },
    change_bookingDate(info){
      const self = this
      var old_therapist = info.org_therapist
      info.org_therapist = []
      if (info.bookingDate.length != 10) {
        var fd_offset = info.bookingDate.getTimezoneOffset()
        var date = new Date(info.bookingDate.getTime() - fd_offset * 60 * 1000)
        info.bookingDate = date.toISOString().split('T')[0]
      }
      self.therapist_list = [
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', info.bookingDate)
      this.formData.append('end_booking_date', info.bookingDate)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            item.text = item.nickname
            item.name = item.nickname
            item.color = '#ededed'
            self.therapist_list.push(item)
          })
          old_therapist.forEach((item) => {
            var index = self.therapist_list.findIndex(el => el.id == item)
            if(index >= 0){
              info.org_therapist.push(item)
            }
          })
        }
        self.bookingDatedataSource = response.data.data
        self.therapist_check()
      })
    },
    home_page(){
      document.getElementById('confirmModal_close').click()
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.modal {
  top: -20px !important;
}

.disable-date {
  background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.2) 4px, transparent 4px, transparent 9px );
}

.genderN {
  border-left: 10px solid white !important;
  border-top: 10px solid white !important;
  border-bottom: 10px solid white !important;
  background-color: white !important;
}

.genderM {
  border-left: 10px solid #a9e7f3 !important;
  border-top: 10px solid #a9e7f3 !important;
  border-bottom: 10px solid #a9e7f3 !important;
}

.genderF {
  border-left: 10px solid #ffd5df !important;
  border-top: 10px solid #ffd5df !important;
  border-bottom: 10px solid #ffd5df !important;
}

.genderMBG{
  background-color: #a9e7f3 !important;
}

.genderFBG{
  background-color: #ffd5df !important;
}

.vtl-tbody-td{
  padding: 0.3rem !important;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}

.fa-phone  {
  color: rgb(4, 97, 66);
}

.fa-star  {
  color: #c9c930;
}

.offcanvas-width{
  width: 550px !important;
}

.offcanvas-width2{
  width: 700px !important;
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

.need_update {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.tab-content {
  padding: 0px !important; 
}

.nav-link {
  color: #525c6a;
}

.nav-tabs {
  border-bottom: 0px solid;
}

.multiselect-dropdown {
  height: 10rem !important;
}

.tooltip{
  font-size: 20px !important;
}

.vtl-table {
  max-height: calc(100vh - 320px) !important;
}
</style>